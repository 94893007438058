import React from "react";
import { t } from "../utils/i18n";

import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Box,
  CssBaseline,
  Typography,
  SvgIcon,
} from "@material-ui/core";

// Components
import Link from "../components/Link";
import SigninForm from "../components/SigninForm";

import { ReactComponent as SplotchIcon } from "../assets/svg/splotch.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1605,
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    alignItems: "center",
  },
  logo: {
    width: 90,
    height: 90,
    maxWidth: 90,
    marginBottom: 20,
    marginTop: 20,
    backgroundImage: "url(/mediaforta-logo-blue.png)",
    backgroundSize: "auto 100%",
    backgroundRepeat: "no-repeat",
  },
  header: { "& h1": { fontWeight: "500 !important" } },
  box: {
    width: 800,
    flexDirection: "column",
    "& > *": { margin: 10 },
  },
  bottomWrapper: {
    backgroundColor: "#f0f3f5",
    padding: "40px 0px",
  },
  title: {
    textAlign: "center",
    marginBottom: 15,
    color: "#17285d",
    fontSize: "45px",
    "&.MuiTypography-h5": {
      fontSize: "19px",
      fontWeight: 300,
    },
  },
  action: {
    textAlign: "center",
    position: "relative",
    width: 450,
    margin: "auto",
  },
  splotch: {
    position: "relative",
    width: 100,
    height: 100,
    color: theme.palette.mediaforta.main,
  },
  splotchWrapper: {
    "& > *": {
      flex: 1,
      textAlign: "center",
      color: theme.palette.mediaforta.main,
      position: "relative",
      "& > div": { fontSize: 60 },
      "& > label": {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: "auto",
        height: 30,
        width: 17,
        fontSize: "19px",
        textAlign: "left",
        fontWeight: 900,
        color: "#fff",
      },
    },
  },
  stepInfoWrapper: {
    width: "130%",
    marginLeft: "-10%",
    "& > *": {
      flex: 1,
      "& > h1": {
        textAlign: "left",
        padding: "0 10px",
        fontSize: "22px",
        "&.MuiTypography-h5": { fontSize: "17px" },
      },
    },
  },
}));

export default function CompleteRegistration() {
  const classes = useStyles();
  const CustomSplotchIcon = () => (
    <SvgIcon className={classes.splotch}>
      {" "}
      <SplotchIcon />{" "}
    </SvgIcon>
  );

  return (
    <Container component="main" maxWidth={false} className={classes.root}>
      <CssBaseline />
      <Link className={classes.logo} href="/"></Link>
      <Box display="flex" className={classes.box}>
        <Container component="main" maxWidth={false} className={classes.header}>
          <Container component="main" maxWidth={false}>
            <Box>
              <Typography className={classes.title} component="h1" variant="h2">
                {" "}
                {t("welcome_back")}{" "}
              </Typography>
            </Box>
          </Container>
          <Container component="main" maxWidth={false}>
            <Box>
              <Typography className={classes.title} component="h1" variant="h5">
                {" "}
                {t("complete_info", true)}{" "}
              </Typography>
            </Box>
          </Container>
        </Container>
        <Container component="main" maxWidth={false}>
          <Box className={classes.action}>
            <SigninForm />
          </Box>
        </Container>
      </Box>
      <Container
        component="main"
        maxWidth={false}
        className={classes.bottomWrapper}
      >
        <Box display="flex" className={classes.box} style={{ margin: "auto" }}>
          <Container component="main" maxWidth={false}>
            <Box>
              <Typography className={classes.title} component="h1" variant="h2">
                {" "}
                {t("how_does_it_work")}.{" "}
              </Typography>
            </Box>
          </Container>
          <Container component="main" maxWidth={false}>
            <Box>
              <Typography className={classes.title} component="h1" variant="h5">
                {" "}
                {t("how_does_it_work_info", true)}{" "}
              </Typography>
            </Box>
          </Container>
          <Container component="main" maxWidth={false}>
            <Box display="flex" className={classes.splotchWrapper}>
              <Box style={{ width: 100 }}>
                <CustomSplotchIcon />
                <label>1</label>
              </Box>
              <Box>
                {" "}
                <div>&#8594;</div>{" "}
              </Box>
              <Box style={{ width: 100 }}>
                <CustomSplotchIcon />
                <label>2</label>
              </Box>
              <Box>
                {" "}
                <div>&#8594;</div>{" "}
              </Box>
              <Box style={{ width: 100 }}>
                <CustomSplotchIcon />
                <label>3</label>
              </Box>
            </Box>
          </Container>
          <Container component="main" maxWidth={false}>
            <Box display="flex" className={classes.stepInfoWrapper}>
              <Box>
                <Typography
                  className={classes.title}
                  component="h1"
                  variant="h4"
                >
                  {" "}
                  {t("activate_question")}{" "}
                </Typography>
                <Typography
                  className={classes.title}
                  component="h1"
                  variant="h5"
                >
                  {" "}
                  {t("activate_question_info", true)}{" "}
                </Typography>
              </Box>
              <Box>
                <Typography
                  className={classes.title}
                  component="h1"
                  variant="h4"
                >
                  {" "}
                  {t("activate_meet")}{" "}
                </Typography>
                <Typography
                  className={classes.title}
                  component="h1"
                  variant="h5"
                >
                  {" "}
                  {t("activate_meet_info", true)}{" "}
                </Typography>
              </Box>
              <Box>
                <Typography
                  className={classes.title}
                  component="h1"
                  variant="h4"
                >
                  {" "}
                  {t("activate_match")}{" "}
                </Typography>
                <Typography
                  className={classes.title}
                  component="h1"
                  variant="h5"
                >
                  {" "}
                  {t("activate_match_info", true)}{" "}
                </Typography>
              </Box>
            </Box>
          </Container>
        </Box>
      </Container>
    </Container>
  );
}
