import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { t } from "../utils/i18n";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Box,
  CssBaseline,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";
import Reaptcha from "reaptcha";

// components
import SigninForm from "../components/SigninForm";
import LanguageSwitcher from "../components/LanguageSwitcher";
import JobsService from "../services/jobs.service";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1240,
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    alignItems: "center",
    marginTop: 220,
  },
  paper: {
    margin: "auto",
    width: 90,
    maxWidth: "90px !important",
    height: 90,
    backgroundImage: "url(/mediaforta-logo-blue.png)",
    backgroundSize: "auto 100%",
  },
  box: {
    "& > *": {
      padding: 30,
      margin: 30,
    },
    "& .MuiInputBase-root > fieldset": { borderColor: "#ccc" },
  },
  register: {
    backgroundColor: "#f0f3f5",
    "& .MuiFormControl-root": {
      backgroundColor: "#eaedf2",
      marginBottom: 20,
    },
  },
  title: {
    textAlign: "center",
    marginBottom: 30,
    fontSize: 15,
  },
  header: {
    maxWidth: "100vw !important",
    position: "absolute",
    background: "#17285d",
    color: "#fff",
    padding: "25px 80px",
    width: "100vw",
    left: 0,
    maxHeight: 240,
    top: 0,
  },
  campaigntiltle: {
    fontSize: "1.2rem",
    color: "#16cc94",
    float: "right",
  },
  jobtiltle: {
    fontSize: "2rem",
    paddingTop: 10,
  },
  jobdescription: {
    fontSize: "1rem",
    maxWidth: "80%",
    paddingBottom: 20,
  },
  logo: {
    height: 40,
    position: "fixed",
    top: 15,
    left: 80,
  },
}));

export const fetchCampaignJob = async (campaignId, jobId) =>
  JobsService.getCampaignJob(campaignId, jobId);
export default function CampaignJobHome({ match = { params: {} } }) {
  const classes = useStyles();
  const history = useHistory();

  const {
    params: { jobId, campaignId },
  } = match;
  const [verified, setVerified] = React.useState(true);

  const [firstname, setFirstname] = React.useState("");
  const [lastname, setLastname] = React.useState("");
  const [campaignJob, setCampaignJob] = React.useState(false);

  if (campaignId && jobId && !campaignJob) {
    fetchCampaignJob(campaignId, jobId).then(setCampaignJob);
  }

  return (
    campaignJob &&
    campaignJob.campaign &&
    campaignJob.job && (
      <Container>
        <img src="/MF_logo_2019-2-1.png" className={classes.logo} />
        <Box className={classes.header}>
          <Typography
            className={classes.campaigntiltle}
            component="h1"
            variant="h5"
          >
            {" "}
            {campaignJob.campaign.title}{" "}
          </Typography>
          <Typography className={classes.jobtiltle} component="h1" variant="h6">
            {" "}
            {campaignJob.job.title}{" "}
          </Typography>
          <Typography
            className={classes.jobdescription}
            component="h2"
            variant="h6"
          >
            <div
              dangerouslySetInnerHTML={{ __html: campaignJob.job.introduction }}
            />
          </Typography>
        </Box>
        <Container component="main" maxWidth={false} className={classes.root}>
          <CssBaseline />
          <Box display="flex" className={classes.box}>
            <Container component="main" maxWidth={false}>
              <Box>
                <Typography
                  className={classes.title}
                  component="h1"
                  variant="h5"
                >
                  {" "}
                  {t("existing_user_title_apply")}{" "}
                </Typography>
                <SigninForm />
              </Box>
            </Container>
            <Container
              component="main"
              maxWidth={false}
              className={classes.register}
            >
              <Box>
                <Typography
                  className={classes.title}
                  component="h1"
                  variant="h5"
                >
                  {" "}
                  {t("new_user_title_apply")}{" "}
                </Typography>
                <TextField
                  value={firstname}
                  name="firstname"
                  type="text"
                  variant="outlined"
                  label={t("firstname")}
                  onChange={(event) => setFirstname(event.currentTarget.value)}
                  fullWidth
                />
                <TextField
                  value={lastname}
                  name="lastname"
                  type="text"
                  variant="outlined"
                  label={t("lastname")}
                  onChange={(event) => setLastname(event.currentTarget.value)}
                  fullWidth
                />
                <div
                  style={{ transform: "scale(0.8)", transformOrigin: "0 0" }}
                >
                  <Reaptcha
                    sitekey="6LfuNZofAAAAAB2D_DCaLmutOrJneYQPqbXknuPb"
                    onVerify={() => setVerified(true)}
                  />
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ backgroundColor: "#0df394", color: "#041e7a" }}
                  size="large"
                  disabled={!verified}
                  onClick={() =>
                    history.push("/register", {
                      jobId,
                      campaignId,
                      user: { firstname, name: lastname },
                    })
                  }
                >
                  {t("create_account")} &gt;
                </Button>
              </Box>
            </Container>
          </Box>
        </Container>
      </Container>
    )
  );
}
