export const TRANSLATIONS_EN = {
  existing_user_title: "Already have an account?",
  existing_user_title_apply:
    "Already have an account? Please login to apply for the job.",
  new_user_title: "Are you new?",
  new_user_title_apply: "Are you new? Please create an account first",
  edit_task: "Edit task",
  new_task: "New task",
  all: "All",
  active: "Active",
  unfinished: "Incomplete",
  finished: "Complete",
  forgot_password: "Forgot password?",
  choose_password: "Create a new password",
  email_sent_title: "We’ve sent you an email.",
  email_sent_info:
    "We’ve sent an email to confirm your registration.<br/>You must confirm your email address to proceed.",
  email_sent_spam: "Be sure to check your junk mail or spam folder, too",
  email_not_received: "Didn’t receive an email?",
  resend: "Resend email",
  create_account: "Create account",
  hello: "Hello",
  join_us:
    "Join us and have the chance to work for top companies <br/>who are looking for high quality content.",
  account_details: "Account details",
  testimonial_century21:
    '"Mediaforta provides high quality content for our international B2B target group" <label>Kim R. COO <br/>Century21</label>',
  add_link: "Add link",
  personal_website: "Personal website",
  fill_personal_website: "Enter the URL of your personal website ",
  are_you_sure: "Are you sure?",
  confirm_remove_job: "Are you sure you want to remove this job?",
  edit_job: "Edit job",
  freelance_visibility: "Freelance and Visibility",
  remove_job: "Remove job",
  remove_job_application:
    "Are you sure you want to remove this job application",
  add_job: "Add job",
  new_job: "New job",
  cancel: "Cancel",
  experience: "Experience",
  add_work: "Add work experience",
  edit_experience: "Edit experience",
  new_experience: "New experience",
  education: "Education",
  add_education: "Add education",
  edit_education: "Edit diploma",
  new_education: "New diploma",
  diplomas: "Diplomas",
  add_diploma: "Add diploma",
  candidates: "Candidates",
  personal_info: "Tell us a bit more about yourself",
  work_experience: "Work experience",
  none: "None",
  edit_candidate: "Edit candidate",
  delete_candidate: "Delete candidate",
  edit_profile: "Edit your profile",
  back_candidates: "Back to all candidates",
  actions: "Actions",
  missing_field: "Missing field",
  field_required: "{{field}} is required",
  required: "Required",
  welcome_back: "Welcome back",
  activation_info:
    "We still need a few details to create a good match between you and our clients.",
  complete_info:
    "We still need a few details to create a good match between you and our clients.",
  get_started: "Get started",
  confirm_remove_calendar_item:
    "Are you sure you want to remove this calendar item?",
  great_success: "Good luck",
  calendar_item_removed: "Calendar item removed",
  calendar_item_added: "Calendar item added",
  calendar_item_updated: "Calendar item updated",
  sure_remove_score: "Are you sure you want to remove this score?",
  sure_remove_test: "Are you sure you want to remove this test?",
  sure_generate_qr: "Are you sure you want to regenarate 2fa code",
  search_tests: "Search tests",
  remark_required: "A remark is required",
  add_score: "Add score",
  edit_score: "Edit score",
  test_updated: "Test updated",
  test_created: "Test created",
  createdAt: "created at",
  createdAt_from: "created from",
  createdAt_to: "created untill",
  modifiedAt: "modified at",
  modifiedByRecruiterAt: "modified by recruiter at",
  unavailable: "unavailable",
  name: "Name",
  firstname: "First name",
  lastname: "Last name",
  add_file: "Add file",
  edit_test: "Edit test",
  new_test: "New test",
  add_test: "Add test",
  sure_remove_task: "Are you sure you want to remove this task?",
  type: "Type",
  due_date: "Due date",
  assigned_to: "Assigned to",
  tasks: "Tasks",
  search_tasks: "Search tasks",
  todo: "To do",
  call: "Call",
  title_short: "Title too short",
  min_chars: "Please enter at least {{amount}} characters",
  task_updated: "Task updated",
  task_created: "Task created",
  title: "Title",
  assign_to: "Assign to",
  description: "Description",
  add_task: "Add task",
  add_tag: "Add tag",
  email: "Email",
  password: "Password",
  login: "Log in",
  email_forgot_password_link: "Link to reset email password",
  reset_failed: "User not found",
  search: "Search",
  search_candidates: "Search candidates",
  invalid_image_url: "Please fill in a valid image URL",
  image_url: "Image URL",
  passwords_no_match: "Passwords do not match",
  confirm_password: "Repeat your password",
  save_password: "Save password",
  dutch: "Dutch",
  french: "French",
  english: "English",
  i_am: "I am a...",
  Writer: "Copywriter / writer",
  my_lang: "Language...",
  lets_go: "Let’s go",
  recruiter: "Recruiter",
  sure_remove_note: "Are you sure you want to remove this note?",
  search_notes: "Search notes",
  score_low: "Score too low",
  score_high: "Score too high",
  edit_note: "Edit note",
  new_note: "New note",
  add_note: "Add note",
  note_created: "Note created",
  note_updated: "Note updated",
  jobs: "Jobs",
  agenda: "Schedule",
  stats: "Statistics",
  add_filter: "Add filter",
  logout: "Log out",
  profile: "Profile",
  work: "Work",
  links: "Links",
  something_wrong: "Something went wrong.",
  contact_recruiter: "Please contact us at recruiter@mediaforta.be",
  future: "Future",
  ongoing: "Ongoing",
  search_jobs: "Search jobs",
  filter_status: "Filter status",
  job_title: "Job title",
  client: "Client",
  select_future_date: "Please select a date in the future",
  function: "Function",
  start_date: "Start date",
  end_date: "End date",
  language: "Language",
  salary: "Salary",
  location: "Location",
  type_of_employment: "Type of employment",
  level_of_applicant: "Level of applicant",
  time: "Time",
  work_for: "Who will you work for?",
  job_introduction: "Job introduction",
  look_for: "Who are we looking for?",
  what_offer: "What we offer?",
  save: "Save",
  tags: "Tags",
  add: "Add",
  past_image_url: "Copy the image URL",
  search_emails: "Search emails",
  from: "From",
  to: "To",
  mail: "Email",
  message_to_short: "Message too short",
  no_candidate_found: "No candidate found",
  scheduled_send: "Scheduled sending",
  subject: "Subject",
  message: "Message",
  send: "Sent",
  invalid_email: "Invalid email address",
  no_tags_assigned: "No tags assigned",
  no_source_assigned: "No source assigned",
  add_source: "Add source",
  new_applicant: "New applicant",
  test: "Test",
  live_interview: "Live interview",
  offer: "Offer",
  archived: "Archived",
  archive: "Archive",
  status: "Status",
  specialisation: "Specialisation",
  specialisations: "Specialisations",
  evaluation: "Evaluation",
  add_skill: "Add skill",
  very_good: "++ Very good",
  good: "+ Good",
  bad: "- Bad",
  very_bad: "-- Very bad",
  interview_created: "Interview created",
  interview_updated: "Interview updated",
  interview_removed: "Interview removed",
  no_links_assigned: "No links assigned",
  search_interviews: "Search interviews",
  start_interview: "Start interview",
  interview_by: "Interview by",
  interview: "Interview",
  new_interview: "Add Interview",
  sure_remove_interview: "Are you sure you want to remove this interview?",
  question: "Question",
  new_question: "New question",
  edit_question: "Edit question",
  sure_remove_question: "Are you sure you want to remove this question?",
  answer: "Answer",
  untill: "untill",
  available_from: "available from",
  available_to: "available to",
  remove_question: "Remove question",
  remove: "Remove",
  add_question: "Add question",
  sure_remove_experience:
    "Are you sure you want to remove this work experience?",
  company: "Company",
  worked_as_freelancer: "Worked as freelancer",
  function_short: "Position too short",
  company_short: "Company too short",
  add_experience: "Add work experience",
  new_email: "New email address",
  view_email: "View email address",
  add_email: "Add email address",
  sure_remove_degree: "Are you sure you want to remove this degree?",
  degree: "Degree",
  university: "University",
  country: "Country",
  institute_to_short: "Institute too short",
  degree_to_short: "Degree too short",
  edit_degree: "Edit degree",
  add_degree: "Add degree",
  full_profile: "Full profile",
  status_interview: "Status interview",
  phone_interview: "Phone interview",
  add_candidate_link: "Add candidate link",
  candidate_source: "Candidate source",
  street: "Street",
  number: "Number",
  box: "PO Box",
  postal: "Post code",
  city: "City",
  phone: "Phone",
  skype: "Skype",
  sure_remove_file: "Are you sure you want to remove this file?",
  sure_archive_candidate: "Are you sure you want to archive this candidate?",
  update_candidate: "Update candidate",
  update_profile: "Update profile",
  attachments: "Attachments",
  no_attachments_assigned: "No attachments assigned",
  link_candidate_job: "Link candidate to job",
  no_job_applications_assigned: "No job applications assigned",
  source: "Source",
  info: "Information",
  sure_remove_candidate: "Are you sure you want to remove this candidate?",
  job_applications: "Job applications",
  pending: "Pending",
  rejected: "Rejected",
  successful: "Successful",
  search_job_applications: "Search job applications",
  employer: "Employer",
  application_date: "Application date",
  search_for_similar_jobs: "Search for similar jobs",
  projects: "Projects",
  preferred_projects: "Which type(s) of projects do you prefer to work on?",
  add_skills: "Add your skills...",
  remove_skill: "Remove skill",
  remove_skill_item: "Remove skill item",
  skills: "Skill set",
  skills_categories: "Skill set categories",
  which_skills: "What are your skills?",
  max_5_skills:
    'Choose up to five skills that suit you best, and then click "next".',
  which_ux_writer_skills: "Choose your specialization as a UX copywriter:",
  which_writer_skills: "What topics can you write about well?",
  max_5_writer_skills:
    "Select up to five topics in which you have writing experience and expertise, then click 'Next'.",
  find_skill: "Didn’t find what you were looking for?",
  add_new_skill: "Add a new skill",
  next: "Next",
  add_remark: "Add a comment",
  remark: "Comment",
  selected_skills: "Your selected specialisations:",
  manage_skills: "Edit your skills...",
  existing: "Existing",
  for_validation: "To be verified",
  add_specialisation: "Add specialisation",
  edit_specialisation: "Edit specialisation",
  new_specialisation: "New specialisation",
  edit_specialisation_category: "Edit specialisation category",
  new_specialisation_category: "New specialisation category",
  submitted_by: "Submitted by",
  submitted_on: "Submitted on",
  validation: "Validation",
  category: "Category",
  label: "Label",
  no_pending_skills: "No skills pending",
  see_candidates: "See candidates",
  decline: "Decline",
  approve: "Approve",
  jobapplication_mail_body: "declined job application email default message",
  jobapplication_mail_subject: "declined job application email default subject",
  jobapplication_popup_title: "Declined email message",
  no_jobs_found: "No jobs found",
  fill_in_user_create_fields:
    "Please fill in all fields and ensure the email address is unique",
  personal_data: "Details",
  contact: "Contact",
  project_preferences: "Which type of projects do you prefer to work on?",
  top_project: "Which project are you most proud of?",
  work_as_freelancer: "Do you work as a freelancer?",
  public_enabled:
    "Mediaforta can share my profile with prospects and clients of Mediaforta",
  yes: "Yes",
  no: "No",
  avatar: "Profile photo",
  personal_details: "Personal details",
  professional_details: "Professional details",
  years_experience: "Years of experience",
  native_language: "Native language",
  translations: "Translations",
  translate_from: "Translate from",
  translate_to: "Translate into",
  add_language: "Add a language",
  profile_picture: "Profile photo",
  choose_file: "Choose file",
  rates: "Rates",
  no_price_set: "No rates set",
  dayRate: "Day rate (in euro)",
  pricePerWord: "Price per word (in euro)",
  pricePerHour: "Price per hour (in euros)",
  cv_resume: "CV/resume",
  portfolio: "Portfolio",
  registration_completed_title: "Thanks for registering!",
  registration_completed_sub:
    "We will contact you as soon as possible <br/> regarding the next steps.",
  vacancies: "Job opportunities",
  goto_vacancies: "View job opportunities",
  blogposts: "Blogposts",
  goto_blogposts: "View blogposts",
  your_profile: "Your profile",
  goto_your_profile: "View your profile",
  no_translations: "No translations",
  read_more: "Read more",
  please_select_one_skill: "Please select at least one skill",
  no_mothertongue: "No native language selected",
  no_skillset: "No skills selected",
  close_filter: "Close filter",
  how_does_it_work: "How does it work?",
  how_does_it_work_info:
    "Mediaforta has an exlcusive network of the best content creation talent available. We offer access to top companies, a community of experts and resources that can accelerate your career.",
  activate_question: "Selection procedure",
  activate_question_info:
    "As a quality-first company, Mediaforta selects the best content creators from among the candidates. Our effective selection process ensures that our clients work with the best freelance content creators and content marketeers.",
  activate_meet: "Get assignments",
  activate_meet_info:
    "Once you pass our selection procedure, you become part of our network. If a client is looking for a content creator with your skills and specialties’, we introduce you. Ultimately, you decide whether you take the job or not.",
  activate_match: "Get paid properly",
  activate_match_info:
    "We compete on the value and quality of our services. That’s why we pay our freelance content creators a fair and proper price for their work.",
  testimonial_ludovic:
    '"As a freelancer, I find Mediaforta a good company to work with. Clear agreements, to-the-point communication and the chance to work for large and promising enterprises." <label>Ludovic <br/>Freelance copywriter</label>',
  privacy_check:
    'By registering you confirm that you have read and agree to our <a href="https://www.mediaforta.com/algemene-voorwaarden/" target="_blank">Terms and Conditions</a>, <a href="https://www.mediaforta.com/privacyverklaring-mediaforta/" target="_blank">Privacy Policy</a> and <a href="https://www.mediaforta.com/cookie-policy/" target="_blank">Cookie Policy</a>.',
  newsletter_check:
    "Don`t miss anything. Keep me informed via email about interesting jobs and inspiring content.",
  trusted_by: "Trusted by",
  copywriter: "Copywriter",
  uxcopywriter: "UX copywriter",
  translator: "Translator",
  contentmarketer: "Content marketer",
  sure_remove_recruiter_role: "Are you sure you want to remove recruiter role?",
  new_recruiter: "Add new recruiter",
  remove_account: "remove my account",
  why_remove_account: "Can you indicate why you want to stop Mediaforta",
  keep_data_and_mail: "Mediaforta can still keep my data and email me",
  delete_data_and_mail: "Mediaforta is no longer allowed to store my data",
  choose_more: "Choose more",
  add_category: "Add category",
  change: "Change",
  remove_category:
    "Are you sure you want to remove this category from current scorecard?",
  manage_items: "Manage items",
  manage: "Manage",
  search_or_add: "Search or add new",
  remove_score_card: "Are you sure you want to remove this scorecard?",
  at_least_one_price_required: "Please fill a dayprice or price per word",
  always_available: "Always available",
  privacy:
    '<a target="_blank" href="https://www.mediaforta.com/en/mediaforta-privacy-policy/">Privacy statement</a>',
  cookie_policy:
    '<a target="_blank" href="https://www.mediaforta.com/en/cookie-policy/">Cookie Policy</a>',
  terms_and_conditions:
    '<a target="_blank" href="https://www.mediaforta.com/en/general-terms-and-conditions/">Terms and conditions</a>',
  Designer: "Graphic designer",
  skills_stars:
    "The number of stars indicates how proficient you are in this skill.",
  digitalmarketer: "Digital marketer",
  socialmediacontentcreator: "Social media content creator",
};
